html, body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #333;
}

td {
  overflow: visible !important;
}

.container {
  margin-left: 56px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: 0;
}