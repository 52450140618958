.menu {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row !important;
  display: flex;
  flex-wrap: wrap;
}

.menu .json {
  padding: 25px;
  width: 100vw;
  background-color: var(--base-highlight-color);
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.top-menu {
  max-width: 25vw;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  background-color: #f2f2f2;
  align-self: stretch;
  z-index: 2;
}

.top-menu-item, .sub-menu-item, .web-menu-item {
  margin-left: 50px;
  max-width: 25vw;
  background-color: #f2f2f2;
  align-self: stretch;
  z-index: 2;
}

.sub-menu-item, .web-menu-item {
  padding: 20px;
  width: 100%;
}

.menu-items {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.hero-image {
  width: 100%;
}

.tile-image {
  width: 50%;
}

.tile-image-container {
  transition: ease-out 0.08s;
  overflow: hidden;
}

.tile-image-container.selected {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  transform: scale(1.08, 1.08) !important;
  z-index: 10;
}

.tile-image-container.selected img {
  transform: scale(1.0, 1.0) !important;
}

.tile-image img {
  transition: ease-out 0.08s;
  position: relative;
  z-index: -1;
}

.tile-image:hover img {
  transform: scale(1.05, 1.05);
}