.product {
	display: flex;
	border-bottom: 1px solid #eee;
}

.product .media {
	width: 43vw;
	align-items: flex-start;
	justify-content: flex-start;
	display: flex;
	flex-wrap: wrap;
}

.product .media > img {
	width: 50%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.product-json {
	padding: 25px;
	background-color: var(--base-highlight-color);
	border-bottom: 1px solid #eee;
	margin-left: 43vw;
}

.attribute-id {
	font-size: calc(var(--base-font-size) * 0.75);
	font-weight: 800;
	text-transform: uppercase;
	margin-right: 5px;
}

.attribute-value {
	font-size: calc(var(--base-font-size) * 0.75);
}

.sale-price {
	color: var(--base-accent-color);
}

.sale-original-price {
	text-decoration: line-through;
	opacity: 0.5;
}

.product p {
	margin-top: 45px;
}

.product-variants {
	width: 19vw;
	box-sizing: border-box;
	background-color: #fff;
	border-left: 1px solid #eee;
}

.product-variants h2 {
	padding: 75px 43px 22px 43px;
}

.product-variant {
	border-top: 1px solid #eee;
}

.product-variant .media {
	width: 100%;
}

.product-variant .media > img {
	width: 11%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.product-variant-info {
	padding: 20px 43px 15px 43px;
}

.product-variant-info .price {
	display: flex;
	font-size: calc(var(--base-font-size) * 0.75);
	text-transform: uppercase;
	letter-spacing: 0.005em;
}

.product-variant-info .price > div {
	margin-right: 4px;
}
