.sidebar-button {
    transition: all 0.2s ease-out;
}

.sidebar-button:hover {
    background-color: #d0d0d0;
    transform: scale(1.2)
}

.sidebar-button-active {
    background-color: #e0e0e0;
    transform: scale(1.2)
}